<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="property-card">
    <div class="row no-gutters">
      <div class="col">
        <div class="p-3 p-md-4">
          <p v-html="formatAddress(property.addressBase)"></p>
          <router-link :to="`/property/${property.uprn}`" role="button" class="btn btn-primary"
            >View property</router-link
          >
        </div>
      </div>
      <div class="col d-none d-md-block">
        <div class="embed-responsive embed-responsive-4by3">
          <iframe
            class="embed-responsive-item"
            :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAOwkawmnua5r1J986HraU6l7OuYnB6LzM&q=${property.addressBase}`"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAddress } from '../helpers/address';

export default {
  name: 'PropertyCard',
  props: {
    property: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    formatAddress,
  },
};
</script>
