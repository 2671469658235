<template>
  <div>
    <!-- <s-e-o title="My Property" /> -->
    <banner title="My property" />
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pull">
            <p class="lead">Let’s get started.</p>
            <p>
              Click ‘view property’ and find out what information is available on your property. You
              will be able to download any documents that are here, including your TrustMark
              Lodgement Certificate.
            </p>
          </div>
          <property-card v-for="property in properties" :key="property.uprn" :property="property" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SEO from './SEO.vuew';
import Banner from './Banner.vue';
import PropertyCard from './PropertyCard.vue';

export default {
  name: 'PropertiesPage',
  components: {
    // SEO,
    Banner,
    PropertyCard,
  },
  computed: {
    properties() {
      return this.$store.state.homeOwner.properties;
    },
  },
  mounted() {
    if (!this.properties) {
      this.$store.dispatch('homeOwner/getProperties');
    }
  },
};
</script>
